import React from "react";
import { Outlet } from "react-router-dom";

import LandingHeader from "../includes/LandingHeader";
import LandingFooter from "../includes/LandingFooter";

class LandingLayout extends React.Component {
    render() {
        document.body.classList.add('home');

        return (
            <>
                <LandingHeader></LandingHeader>
                <Outlet />
                <LandingFooter></LandingFooter>
            </>
        )
    }
}

export default LandingLayout;