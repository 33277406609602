import React from "react";
import { Outlet } from "react-router-dom";

import AppHeader from "../includes/AppHeader";
import AppFooter from "../includes/AppFooter";

class AppLayout extends React.Component {
  render(){
    document.body.classList.remove('home');

    return (
      <>
        <AppHeader></AppHeader>
        <Outlet />
        <AppFooter></AppFooter>
      </>
    );
  }
}
export default AppLayout;